import { Spinner } from "@nextui-org/react";
import CircularSpinner from "./CircularSpinner";

const LoaderComponent = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen z-[9999] flex items-center justify-center">
      <div className="w-full h-screen absolute top-0 backdrop-blur-[20px] backdrop-brightness-75 saturate-[180%]"></div>
      <CircularSpinner size={50} />
    </div>
  );
};

export default LoaderComponent;
